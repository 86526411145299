// dynamic imports need promises & array polyfills for IE
//import "core-js/modules/es6.promise"
//import "core-js/modules/es6.array.iterator"
//

// Entry point for web
// delegate controll to another file so we can use async there
//import app from '@/app.js'

// console.log('INVOKEZORRED');

import './app/';
